// require("dotenv").config({
//   path: `.env.${process.env.NODE_ENV}`,
// })

const {
  NODE_ENV,
  URL: NETLIFY_SITE_URL = "https://www.donkervoort.com",
  DEPLOY_PRIME_URL: NETLIFY_DEPLOY_URL = NETLIFY_SITE_URL,
  CONTEXT: NETLIFY_ENV = NODE_ENV,
} = process.env
const isNetlifyProduction = NETLIFY_ENV === "production"
const siteUrl = isNetlifyProduction ? NETLIFY_SITE_URL : NETLIFY_DEPLOY_URL

//ALGOLIA
const myQuery = `
  query {
    stories: allStoryblokEntry(filter: { lang: { in: ["en"] }, field_component: {in: ["model", "page", "heritage_model", "event", "track", "event_overview", "event_type", "news_overview", "pre_owned_overview", "timeline",  "service", "services_overview", "dealer_overview", ]} }) {
      edges {
        node {
          objectID: id
          name
          created_at
          uuid
          slug
          field_component
          full_slug
          lang
          title: field_title_string
          short_title: field_card_short_title_string
          short_description: field_card_short_description_string
          button_text: field_card_button_text_string
          content
          translated_slugs {
            lang
            path
          }
          sort_by_date
          first_published_at
          published_at
          internal {
            # querying internal.contentDigest is required
            contentDigest
            type
            owner
          }
        }
      }
    }
  }
`

// const flatten = arr =>
//   arr.map(({ node: { content, ...rest } }) => {
//     const parsedContent = JSON.parse(content)
//     ;({ parsedContent, ...rest })
//   })

const flatten = arr =>
  arr.map(
    ({ node: { content, title, ...rest } }) => (
      ((content = JSON.parse(content)),
      (title = content.model_name ? content.model_name : title)),
      { content, title, ...rest }
    )
  )

const queries = [
  {
    query: myQuery,
    transformer: ({ data }) => flatten(data.stories.edges), // optional
    //indexName: "index name to target", // overrides main index name, optional
    settings: {
      // optional, any index settings
      // Note: by supplying settings, you will overwrite all existing settings on the index
    },
    //matchFields: ["slug", "modified"], // Array<String> overrides main match fields, optional
    //mergeSettings: false, // optional, defaults to false. See notes on mergeSettings below
    //queryVariables: {}, // optional. Allows you to use graphql query variables in the query
  },
]

module.exports = {
  siteMetadata: {
    title: `Donkervoort Automobielen`,
    description: `Super cars with an ultra low weight, high power and fantastic grip. Donkervoort makes the hearts of countless car enthusiasts beat faster.`,
    author: `@donkervoort`,
    siteUrl: siteUrl,
  },
  trailingSlash: "always",

  plugins: [
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: "zT1JFT81447tO3944Qf01gtt",
        homeSlug: "home",
        resolveRelations: [
          "event.location_link",
          "event.event_type",
          "event.services",
          "news_item.contacts",
          "news_item.company_summary",
          "job.contact_person",
          //"related_content.content_item_1",
          //"related_content.content_item_2",
          "related_content_items.content_items,",
          "reusable_blok_container.linked_bloks",
          "form_blok.form",
        ],
        resolveLinks: "url",
        version: process.env.NODE_ENV === "production" ? "published" : "draft",
        languages: ["en", "nl"],
      },
    },
    // `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        query: `
        {
          site {
            siteMetadata {
              siteUrl
            }
        }
        allStoryblokEntry(filter: {field_component: {nin: ["global_navi", "label_translations", "card", "contact_person", "footer", "splash_page", "related_content_items", "related_content", "form", "dealer", "news_company_summary", "reusable_blok", "not_found_404"]}, lang: {in: ["en"]}}) {
          edges {
            node {
                published_at(formatString: "YYYY-MM-DD")
                full_slug
                field_component
            }
          }
        }
        }`,
        resolveSiteUrl: ({ site }) => site.siteMetadata.siteUrl,
        resolvePages: ({ allStoryblokEntry: { edges: mdxs } }) => {
          const posts = mdxs.map(post => {
            return {
              path: post.node.full_slug.replace(/\/?$/, "/"),
              lastmod: post.node.published_at,
            }
          })

          const home = {
            path: "/",
            lastmod: posts[0].lastmod,
          }

          return [...posts, home]
        },
        serialize: ({ path, lastmod }) => {
          return {
            url: path,
            lastmod,
          }
        },
        createLinkInHead: true,
      },
    },
    {
      resolve: "gatsby-plugin-robots-txt",
      options: {
        resolveEnv: () => NETLIFY_ENV,
        env: {
          production: {
            policy: [{ userAgent: "*" }],
            sitemap: "https://www.donkervoort.com/sitemap/sitemap-index.xml",
          },
          "branch-deploy": {
            policy: [{ userAgent: "*", disallow: ["/"] }],
            sitemap: null,
            host: null,
          },
          "deploy-preview": {
            policy: [{ userAgent: "*", disallow: ["/"] }],
            sitemap: null,
            host: null,
          },
        },
      },
    },
    `gatsby-plugin-theme-ui`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    // {
    //   resolve: `gatsby-plugin-webfonts`,
    //   options: {
    //     fonts: {
    //       google: [
    //         {
    //           family: "Bebas Neue",
    //           variants: ["400"],
    //           fontDisplay: "swap",
    //         },
    //         {
    //           family: "Poppins",
    //           variants: ["300", "400", "500", "600", "700"],
    //           fontDisplay: "swap",
    //         },
    //       ],
    //     },
    //     usePreload: true,
    //   },
    // },
    // `gatsby-transformer-sharp`,
    // `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Donkervoort Automobielen`,
        short_name: `Donkervoort`,
        lang: "en",
        start_url: `/en/`,
        background_color: `#000`,
        theme_color: `#ffffff`,
        display: `minimal-ui`,
        icon: `src/images/donkervoort-logo-square.png`, // This path is relative to the root of the site.
        localize: [
          {
            start_url: `/nl/`,
            lang: `nl`,
            name: `Donkervoort Automobielen`,
            short_name: `Donkervoort`,
          },
        ],
      },
    },
    {
      resolve: `gatsby-plugin-netlify`,
      options: {
        headers: {
          "/*": [
            "X-XSS-Protection: 1; mode=block",
            "X-Content-Type-Options: nosniff",
            "Referrer-Policy: same-origin",
            `Content-Security-Policy: frame-ancestors 'self' https://app.storyblok.com/`,
          ],
          //"/*": ["Basic-Auth: donkervoort:d8gto"],
        }, // option to add more headers. `Link` headers are transformed by the below criteria
        allPageHeaders: [], // option to add headers for all pages. `Link` headers are transformed by the below criteria
        //mergeSecurityHeaders: true, // boolean to turn off the default security headers
        //mergeLinkHeaders: true, // boolean to turn off the default gatsby js headers
        //mergeCachingHeaders: true, // boolean to turn off the default caching headers
        //transformHeaders: (headers, path) => headers, // optional transform for manipulating headers under each path (e.g.sorting), etc.
        //generateMatchPathRewrites: true, // boolean to turn off automatic creation of redirect rules for client only paths
      },
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: "GTM-NZXRP55",

        // Include GTM in development.
        //
        // Defaults to false meaning GTM will only be loaded in production.
        includeInDevelopment: false,

        // datalayer to be set before GTM is loaded
        // should be an object or a function that is executed in the browser
        //
        // Defaults to null
        defaultDataLayer: { platform: "gatsby" },

        // Specify optional GTM environment details.
        // gtmAuth: "YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_AUTH_STRING",
        // gtmPreview: "YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_PREVIEW_NAME",
        // dataLayerName: "YOUR_DATA_LAYER_NAME",

        // Name of the event that is triggered
        // on every Gatsby route change.
        //
        // Defaults to gatsby-route-change
        //routeChangeEventName: "YOUR_ROUTE_CHANGE_EVENT_NAME",

        // Defaults to false
        enableWebVitalsTracking: true,

        // Defaults to https://www.googletagmanager.com
        //selfHostedOrigin: "YOUR_SELF_HOSTED_ORIGIN",
      },
    },

    {
      // This plugin must be placed last in your list of plugins to ensure that it can query all the GraphQL data
      resolve: `gatsby-plugin-algolia`,
      options: {
        appId: process.env.ALGOLIA_APP_ID,
        // Use Admin API key without GATSBY_ prefix, so that the key isn't exposed in the application
        // Tip: use Search API key with GATSBY_ prefix to access the service from within components
        apiKey: process.env.ALGOLIA_API_KEY,
        indexName: process.env.ALGOLIA_INDEX_NAME, // for all queries
        //indexName: "dev_donkervoort", // for all queries
        queries,
        chunkSize: 100000, // default: 1000
        settings: {
          // optional, any index settings
          // Note: by supplying settings, you will overwrite all existing settings on the index
        },
        enablePartialUpdates: true, // default: false
        matchFields: ["full_slug", "published_at"], // Array<String> default: ['modified']
        concurrentQueries: false, // default: true
        skipIndexing: false, // default: false, useful for e.g. preview deploys or local development
        continueOnFailure: false, // default: false, don't fail the build if algolia indexing fails
        algoliasearchOptions: undefined, // default: { timeouts: { connect: 1, read: 30, write: 30 } }, pass any different options to the algoliasearch constructor
      },
    },

    /* PLUGIN TO CHECK BUNDLE SIZE
    {
      resolve: "gatsby-plugin-webpack-bundle-analyzer",
      options: {
        analyzerPort: 3000,
        //production: true,
      },
    },*/
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
