import Page from "./routes/page"
import Homepage from "./routes/homepage"
import Model from "./routes/model"
import ModelsOverview from "./routes/models_overview"
import HeritageModel from "./routes/model_heritage"
import Event from "./routes/event"
import EventLocation from "./routes/event_location"
import EventsOverview from "./routes/events_overview"
import EventTypeOverview from "./routes/events_overview"
import NewsOverview from "./routes/news_overview"
import PreOwnedOverview from "./routes/pre_owned_overview"
import PreOwned from "./routes/pre_owned"
import Timeline from "./routes/timeline"
import NewsItem from "./routes/news_item"
import Job from "./routes/job"
import JobsOverview from "./routes/jobs_overview"
import ComponentNotFound from "./component_not_found"
import Service from "./routes/service"
import ServicesOverview from "./routes/services_overview"
import ReusableBlok from "./reusable_blok"
import DealerOverview from "./routes/dealer_overview"
import NotFoundPage from "../pages/en/404"
import { Form } from "./form"
import SeachPage from "./routes/search"

const ComponentList = {
  page: Page,
  homepage: Homepage,
  model: Model,
  heritage_model: HeritageModel,
  models_overview: ModelsOverview,
  event: Event,
  location: EventLocation,
  track: EventLocation,
  event_overview: EventsOverview,
  event_type: EventTypeOverview,
  news_overview: NewsOverview,
  pre_owned_overview: PreOwnedOverview,
  pre_owned: PreOwned,
  timeline: Timeline,
  news_item: NewsItem,
  job: Job,
  jobs_overview: JobsOverview,
  service: Service,
  services_overview: ServicesOverview,
  dealer_overview: DealerOverview,
  //INCLUDED FOR PREVIEW
  reusable_blok: ReusableBlok,
  form: Form,
  not_found_404: NotFoundPage,
  search_page: SeachPage,
}

const Components = type => {
  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
